import { useEffect } from 'react';
import { navigate } from 'gatsby';

const LogoutPage = () => {
  useEffect(() => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      localStorage.clear();
      navigate('/auth/login');
    } else {
      navigate('/complaints');
    }
  }, []);

  // This component does not render any UI directly
  return null;
};

export default LogoutPage;
